import { get, post } from 'common/request'

export default {

  // 文件分页列表
  fileList: args => get('/attachFile/my/list', args),

  // 文件分类列表
  fileClassList: args => get('/lbrary/lbraryTreeList', args),

  // 通过Id获取文件信息
  getFileById: args => get('/attachFile/get', args),

  // 文件修改
  updateFile: args => post('/attachFile/save', args),

  // 文件上传
  uploadFile: args => post('/attachFile/upload', args),

  dictData: args => get('/dict-data/getByDicKind', args),

  deptList: args => get('/dept/queryList', args),

  // 目录树
  getTree: args => get('/lbrary/tree?businessType=1', args),
  // 获取组织机构 树结构
  deptTreeList: args => get('/dept/queryTree', args),

  searchByKeywords: params => get('/lbrary/searchBy', params)

}
